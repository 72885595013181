import { useAppSelector } from '@/shared/hooks';
import { FeatureFlag } from '@/shared/api/protocol_gen/model/dto_organization';

import { organizationModel } from '@/entities/organization';

import { GpFDAReport } from '../GpFDAReport';
import { GpReport } from '../GpReport';

export const CBCTGPReportContainer = () => {
  const isFDA = useAppSelector(
    organizationModel.selectors.selectIsFeatureActiveByName(
      FeatureFlag.FeatureFlag_FDA_SubmissionView,
    ),
  );

  return isFDA ? <GpFDAReport /> : <GpReport />;
};
