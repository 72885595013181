import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  ApproveReportToothReq,
  ApproveReportToothResp,
  CreateReportToothConditionsReq,
  CreateReportToothConditionsResp,
  DeleteReportReq,
  DisapproveReportToothReq,
  DisapproveReportToothResp,
  ReportServiceClientImpl,
  RequestReportReq,
  ResetReportToothReq,
  SetReportConclusionReq,
  SetReportPrintSettingsReq,
  SetReportSettingsReq,
  SetReportToothCommentReq,
  SetROIReq,
  SignReportReq,
  SetROIResp,
  ResetReportToothResp,
  SetReportToothCommentResp,
  SignReportResp,
  SetReportConclusionResp,
  ApproveAllTeethAndSignReportReq,
  AddToothDisplaySliceReq,
  AddToothDisplaySliceResp,
  RemoveToothDisplaySliceReq,
  RemoveToothDisplaySliceResp,
  SetReportSettingsResp,
  SetReportPrintSettingsResp,
  RequestReportResp,
  DeleteReportResp,
  SetReportToothNumerationReq,
  SetReportToothNumerationResp,
  SetToothLocalizationCropTopLayerAnnotationsReq,
  SetToothLocalizationCropTopLayerAnnotationsResp,
  SetToothLocalizationCropMedicalImageViewOptionsReq,
  SetToothLocalizationCropMedicalImageViewOptionsResp,
  ChangeToothLocalizationNumerationResp,
  ChangeToothLocalizationNumerationReq,
  SetReportToothConditionsUserDecisionReq,
  SetReportToothConditionsUserDecisionResp,
  SetReportMedicalImageViewOptionsReq,
  SetReportMedicalImageViewOptionsResp,
  SetImageMetaViewOptionsReq,
  SetImageMetaViewOptionsResp,
  SetReportToothConditionUserScoreAndApproveToothReq,
  SetReportToothConditionUserScoreAndApproveToothResp,
} from '@/shared/api/protocol_gen/api/core/svc_report';
import api, { ApiError } from '@/shared/api/api';
import { createThunkGenerator } from '@/shared/lib';
import { SliceName } from '@/shared/config';

// TODO: [4/h] Discuss the possibility of creating an additional 'services' layer in the FSD between entities and features
import { findLowProbabilityConditionIDs } from '@/entities/logicalCondition';
import { ModalID, modalModel } from '@/entities/modal';
import { toothModel } from '@/entities/tooth';

import type { RootState } from '@/app/model/store';

import { CheckReportSignatureModalText } from '../config';

export type ReportRequests = keyof ReportServiceClientImpl;

const generateReportThunk = createThunkGenerator<ReportRequests>(
  SliceName.reports,
  'report',
);

export const setReportToothConditionUserScoreAndApproveTooth =
  generateReportThunk<
    SetReportToothConditionUserScoreAndApproveToothReq,
    SetReportToothConditionUserScoreAndApproveToothResp
  >(
    'SetReportToothConditionUserScoreAndApproveTooth',
    api.report.SetReportToothConditionUserScoreAndApproveTooth,
  );

export const setReportToothConditionsUserDecision = generateReportThunk<
  SetReportToothConditionsUserDecisionReq,
  SetReportToothConditionsUserDecisionResp
>(
  'SetReportToothConditionsUserDecision',
  api.report.SetReportToothConditionsUserDecision,
);

export const createReportToothConditions = generateReportThunk<
  CreateReportToothConditionsReq,
  CreateReportToothConditionsResp
>('CreateReportToothConditions', api.report.CreateReportToothConditions);

export const setReportSettings = generateReportThunk<
  SetReportSettingsReq,
  SetReportSettingsResp
>('SetReportSettings', api.report.SetReportSettings);

export const requestReport = generateReportThunk<
  RequestReportReq,
  RequestReportResp
>('RequestReport', api.report.RequestReport);

export const deleteReport = generateReportThunk<
  DeleteReportReq,
  DeleteReportResp
>('DeleteReport', api.report.DeleteReport);

export const setROI = generateReportThunk<SetROIReq, SetROIResp>(
  'SetROI',
  api.report.SetROI,
);

export const setReportConclusion = generateReportThunk<
  SetReportConclusionReq,
  SetReportConclusionResp
>('SetReportConclusion', api.report.SetReportConclusion);

export const setReportPrintSettings = generateReportThunk<
  SetReportPrintSettingsReq,
  SetReportPrintSettingsResp
>('SetReportPrintSettings', api.report.SetReportPrintSettings);

export const signReport = generateReportThunk<SignReportReq, SignReportResp>(
  'SignReport',
  api.report.SignReport,
);

export const setReportToothComment = generateReportThunk<
  SetReportToothCommentReq,
  SetReportToothCommentResp
>('SetReportToothComment', api.report.SetReportToothComment);

// WARNING: DO NOT USE THIS METHOD IN RELEASE 1.0.0
export const setReportToothNumeration = generateReportThunk<
  SetReportToothNumerationReq,
  SetReportToothNumerationResp
>('SetReportToothNumeration', api.report.SetReportToothNumeration);

export const resetReportTooth = generateReportThunk<
  ResetReportToothReq,
  ResetReportToothResp
>('ResetReportTooth', api.report.ResetReportTooth);

// Changing teeth number to 404 instead of delete
export const changeToothLocalizationNumeration = generateReportThunk<
  ChangeToothLocalizationNumerationReq,
  ChangeToothLocalizationNumerationResp
>(
  'ChangeToothLocalizationNumeration',
  api.report.ChangeToothLocalizationNumeration,
);

// export const approveAllTeethAndSignReport = generateReportThunk<
//   ApproveAllTeethAndSignReportReq,
//   ApproveAllTeethAndSignReportResp
// >('ApproveAllTeethAndSignReport', api.report.ApproveAllTeethAndSignReport);

export const approveAllTeethAndSignReport = createAsyncThunk(
  `${SliceName.reports}/ApproveAllTeethAndSignReport`,
  async (reportID: string, { getState, rejectWithValue }) => {
    const state = getState() as RootState;

    const request: ApproveAllTeethAndSignReportReq = {
      ReportID: reportID,
      TeethConditionIDs: findLowProbabilityConditionIDs(
        state.logicalCondition.toothConditions,
      ),
    };

    try {
      const response = await api.report.ApproveAllTeethAndSignReport(request);

      return response;
    } catch (error: unknown) {
      const { message, type } = error as ApiError;

      return rejectWithValue({ message, type });
    }
  },
);

export const setReportToothApproved = generateReportThunk<
  ApproveReportToothReq,
  ApproveReportToothResp
>('ApproveReportTooth', api.report.ApproveReportTooth);

export const setReportToothDisapproved = generateReportThunk<
  DisapproveReportToothReq,
  DisapproveReportToothResp
>('DisapproveReportTooth', api.report.DisapproveReportTooth);

export const addToothDisplaySlice = generateReportThunk<
  AddToothDisplaySliceReq,
  AddToothDisplaySliceResp
>('AddToothDisplaySlice', api.report.AddToothDisplaySlice);

export const removeToothDisplaySlice = generateReportThunk<
  RemoveToothDisplaySliceReq,
  RemoveToothDisplaySliceResp
>('RemoveToothDisplaySlice', api.report.RemoveToothDisplaySlice);

export const setToothLocalizationCropTopLayerAnnotations = generateReportThunk<
  SetToothLocalizationCropTopLayerAnnotationsReq,
  SetToothLocalizationCropTopLayerAnnotationsResp
>(
  'SetToothLocalizationCropTopLayerAnnotations',
  api.report.SetToothLocalizationCropTopLayerAnnotations,
);

export const setToothLocalizationCropMedicalImageViewOptions =
  generateReportThunk<
    SetToothLocalizationCropMedicalImageViewOptionsReq,
    SetToothLocalizationCropMedicalImageViewOptionsResp
  >(
    'SetToothLocalizationCropMedicalImageViewOptions',
    api.report.SetToothLocalizationCropMedicalImageViewOptions,
  );

export const setImageMetaViewOptions = generateReportThunk<
  SetImageMetaViewOptionsReq,
  SetImageMetaViewOptionsResp
>('SetImageMetaViewOptions', api.report.SetImageMetaViewOptions);

export const setReportMedicalImageViewOptions = generateReportThunk<
  SetReportMedicalImageViewOptionsReq,
  SetReportMedicalImageViewOptionsResp
>(
  'SetReportMedicalImageViewOptions',
  api.report.SetReportMedicalImageViewOptions,
);

export const checkReportSignatureThunk = createAsyncThunk(
  `${SliceName.reports}/checkReportSignature`,
  async (
    options: {
      modalText: CheckReportSignatureModalText;
      toothID?: string;
      onSignatureChecked: () => Promise<void>;
    },
    { dispatch, getState },
  ) => {
    const { toothID, modalText, onSignatureChecked } = options;

    const state = getState() as RootState;
    const isReportSigned =
      state.reports.currentReport?.Signature?.UserSignatures.length;

    if (!isReportSigned) {
      await onSignatureChecked();
    } else {
      // TODO: [1/m] remove disapproveTooth when backend adds functionality for automatic disapproval
      const disapproveTooth = async (toothID: string) => {
        const { Tooth } = await dispatch(
          setReportToothDisapproved({
            ToothID: toothID,
          }),
        ).unwrap();

        if (Tooth) {
          dispatch(toothModel.actions.setNewestOne(Tooth));
        }
      };

      const openConfirmModal = async (onConfirm: () => Promise<void>) => {
        dispatch(
          modalModel.actions.openModal({
            modalID: ModalID.Confirm,
            data: {
              title: modalText.title,
              okText: modalText.confirm,
              description: modalText.description,
              bodyClassName: 'p2',
              cancelButtonVariant: 'tertiary',
              onConfirm: async () => {
                dispatch(modalModel.actions.closeModal(ModalID.Confirm));

                await onConfirm();
              },
            },
          }),
        );
      };

      const checkDisapproveAndContinue = async () => {
        if (toothID) {
          await disapproveTooth(toothID);
        }
        await onSignatureChecked();
      };

      await openConfirmModal(checkDisapproveAndContinue);
    }
  },
);
