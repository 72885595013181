import { FC } from 'react';

import { isPrimaryTooth } from '@/entities/tooth';

import { ToothChartItemClickHandler, ToothItem } from '../../config/types';
import { ToothChart } from '../ToothChart';

type ToothChartMetaItemProps = {
  toothItem: ToothItem;
  handleToothClick: ToothChartItemClickHandler;
  showApprovedStatus?: boolean;
};

export const ToothChartMetaItem: FC<ToothChartMetaItemProps> = (props) => {
  const { toothItem, handleToothClick, showApprovedStatus } = props;

  const chilidItems = toothItem?.childItems;
  const isSupernumeric = (chilidItems?.length ?? 0) > 0;

  const primaryToothItem =
    isSupernumeric &&
    chilidItems?.find((childItem) => isPrimaryTooth(childItem.ISONumber));

  if (isSupernumeric && !primaryToothItem) {
    return (
      <ToothChart.SuperItem
        key={toothItem.id}
        id={toothItem.id}
        status={toothItem.status}
        ISONumber={toothItem.ISONumber}
        isLowerJaw={toothItem.isLowerJaw}
        active={toothItem.active}
        childItems={toothItem.childItems}
        onClick={handleToothClick}
        gridArea={toothItem.gridArea}
        isApproved={showApprovedStatus ? toothItem.isApproved : false}
      />
    );
  }

  return primaryToothItem ? (
    <ToothChart.Item
      key={primaryToothItem.id}
      id={primaryToothItem.id}
      status={primaryToothItem.status}
      ISONumber={primaryToothItem.ISONumber}
      isLowerJaw={primaryToothItem.isLowerJaw}
      active={primaryToothItem.active}
      childItems={primaryToothItem.childItems}
      gridArea={primaryToothItem.gridArea}
      onClick={handleToothClick}
      isApproved={showApprovedStatus ? primaryToothItem.isApproved : false}
    />
  ) : (
    <ToothChart.Item
      key={toothItem.id}
      id={toothItem.id}
      status={toothItem.status}
      ISONumber={toothItem.ISONumber}
      isLowerJaw={toothItem.isLowerJaw}
      active={toothItem.active}
      childItems={toothItem.childItems}
      gridArea={toothItem.gridArea}
      onClick={handleToothClick}
      isApproved={showApprovedStatus ? toothItem.isApproved : false}
    />
  );
};
