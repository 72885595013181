import { both, filter } from 'ramda';

import { Tooth } from '@/shared/api/protocol_gen/model/dto_report_tooth';

import { isToothReportIDEquals } from './isToothReportIDEquals';
import { isToothIsInROI } from './isToothIsInROI';

const toothHasReportID = (reportID: string) => (tooth: Tooth) =>
  tooth.ReportID === reportID;

const toothInROI = (tooth: Tooth) => tooth.IsInROI;

export const filterTeethByReportIDAndROI = (teeth: Tooth[], reportID: string) =>
  filter<Tooth>(both(isToothReportIDEquals(reportID), isToothIsInROI))(teeth);

export const filterTeethByReportIDAndROIWithValidation = (
  teeth: Tooth[],
  reportID: string,
) =>
  teeth.filter(
    (tooth) => toothHasReportID(reportID)(tooth) && toothInROI(tooth),
  );
