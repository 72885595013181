import { OrganizationUserRoleType } from '@/shared/api/protocol_gen/model/dto_organization';

import { PermissionStatuses } from './i18n';

export type PermissionType =
  | 'Owner'
  | 'Clinical Leader'
  | 'Treating Doctor'
  | 'Non-clinical Member';

export const TREATING_DOCTORS_AVAILABLE_ROLES = [
  OrganizationUserRoleType.OrganizationUserRoleTypeTreatingDoctor,
  OrganizationUserRoleType.OrganizationUserRoleTypeClinicalLeader,
  OrganizationUserRoleType.OrganizationUserRoleTypeOwner,
];

export const PERMISSIONS: Record<PermissionType, PermissionStatuses[]> = {
  Owner: [
    PermissionStatuses['Manage payments and subscriptions'],
    PermissionStatuses['Manage company settings'],
    PermissionStatuses['View and manage staff'],
    PermissionStatuses['Share patients'],
    PermissionStatuses['Manage patient’s reports'],
    PermissionStatuses['View and manage patients'],
  ],
  'Clinical Leader': [
    PermissionStatuses['Share patients'],
    PermissionStatuses['View and manage staff'],
    PermissionStatuses['Manage patient’s reports'],
    PermissionStatuses['View and manage patients'],
  ],
  'Treating Doctor': [
    PermissionStatuses['Share patients'],
    PermissionStatuses['Manage patient’s reports'],
    PermissionStatuses['View and manage patients'],
  ],
  'Non-clinical Member': [PermissionStatuses['View and manage patients']],
};

export const CLUSTERS: Record<string, string[]> = {
  ru: ['KG', 'AZ', 'TJ', 'KZ', 'MD', 'AM', 'UZ', 'GE', 'RU', 'BY'],
  eu: [
    'IT',
    'NL',
    'NA',
    'HR',
    'ZW',
    'SI',
    'CZ',
    'DK',
    'FI',
    'IE',
    'DE',
    'HU',
    'PS',
    'BE',
    'ES',
    'FR',
    'RS',
    'SK',
    'GR',
    'LT',
    'CH',
    'LI',
    'PT',
    'BW',
    'AL',
    'ZA',
    'EE',
    'RO',
    'IL',
    'SE',
    'BG',
    'UA',
    'PL',
    'MT',
    'AT',
    'LV',
    'LU',
    'GB',
    'CY',
  ],
  us: ['US', 'MX', 'CR', 'CO'],
  ca: ['CA'],
  oceania: ['AU', 'NZ'],
};
