import { includes, map, partialRight } from 'ramda';

import { Condition } from '@/shared/api/protocol_gen/model/dto_report_condition';
import { ToothStatus } from '@/shared/config';
import { ConditionCode } from '@/shared/api/protocol_gen/model/dto_report_condition_codes';
import { Decision } from '@/shared/api/protocol_gen/model/dto_report_common';

import { shouldConditionItemBeShown } from './shouldConditionItemBeShown';
import { getCondtionStatus } from './getCondtionStatus';

export const isToothMissing = (conditions: Condition[]) =>
  conditions.find(
    (condition) =>
      condition.Code === ConditionCode.Missing &&
      condition.Certainty?.EngineDecision === Decision.PositiveDecision,
  );

export const getToothStatus = (
  toothConditions: Condition[],
  showLowProbability = false,
  isFDA = false,
  isFDANonAided = false,
) => {
  const filteredConditions = toothConditions
    .filter((condition) =>
      shouldConditionItemBeShown(showLowProbability)(condition),
    )
    .filter((condition) => {
      if (!isFDA) {
        return true;
      }

      const allowedCodes = [ConditionCode.Missing, ConditionCode.CodeTooth];

      if (!isFDANonAided) {
        allowedCodes.push(ConditionCode.PeriapicalRadiolucency);
      }

      return allowedCodes.includes(condition.Code);
    });

  const allToothStatusByConditions = map(
    partialRight(getCondtionStatus, [isToothMissing]),
    filteredConditions,
  );

  if (isToothMissing(filteredConditions)) {
    return ToothStatus.missing;
  }

  if (includes(ToothStatus.lowProbability, allToothStatusByConditions)) {
    return ToothStatus.lowProbability;
  }

  if (includes(ToothStatus.unhealthy, allToothStatusByConditions)) {
    return ToothStatus.unhealthy;
  }

  if (includes(ToothStatus.treated, allToothStatusByConditions)) {
    return ToothStatus.treated;
  }

  return ToothStatus.healthy;
};
