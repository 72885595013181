import cn from 'classnames';
import { useIntl } from 'react-intl';
import { createColumnHelper } from '@tanstack/react-table';

import { Patient } from '@/shared/api/protocol_gen/model/dto_patient';
import studyPlaceholderImage from '@/shared/assets/images/cats/patientListStudyPlaceholder.png';
import { useAppSelector } from '@/shared/hooks';
import { PatientsListColumnName } from '@/shared/config';
import { FeatureFlag } from '@/shared/api/protocol_gen/model/dto_organization';

import { getImageSrc } from '@/entities/assets';
import { StudiesCounters, patientModel } from '@/entities/patient';
import { organizationModel } from '@/entities/organization';

import { PatientDoctrosCell } from '../ui/PatientDoctrosCell/PatientDoctrosCell';
import styles from '../ui/PatientList/PatientList.module.scss';
import { ColumnValues } from '../config/i18n';
import { PatientNameTooltip } from '../ui/PatientNameTooltip/PatientNameTooltip';

interface ColumnMeta {
  isVisible?: boolean;
}

export const useGetPatientsColumnsScheme = () => {
  const { formatMessage, formatDate } = useIntl();

  const columnHelper = createColumnHelper<Patient>();

  const patientLastReportAssetIDs = useAppSelector(
    patientModel.selectors.selectPatientsLastReportAssetIDs,
  );

  const isFDA = useAppSelector(
    organizationModel.selectors.selectIsFeatureActiveByName(
      FeatureFlag.FeatureFlag_FDA_SubmissionView,
    ),
  );

  const columnsScheme = [
    columnHelper.accessor(
      (row) => ({
        patientFullName: `${row.PersonalData?.FirstName} ${row.PersonalData?.LastName}`,
        displayAssetID:
          row.DisplayAssetID || patientLastReportAssetIDs[row.ID]?.[0],
      }),
      {
        id: PatientsListColumnName.PatientName,
        enableSorting: true,
        minSize: 180,
        header: formatMessage(ColumnValues[PatientsListColumnName.PatientName]),
        cell: (cell) => {
          const { patientFullName, displayAssetID } = cell.getValue();
          const patientStudyImage = getImageSrc(displayAssetID, 'thumbnail');

          return (
            <div className={styles.infoInCell}>
              {displayAssetID ? (
                <object
                  data={patientStudyImage}
                  type="image/png"
                  className={cn(styles.patientStudyImage)}
                >
                  <img
                    src={studyPlaceholderImage}
                    alt={`${patientFullName}-avatar`}
                    className={cn(styles.patientStudyImage)}
                  />
                </object>
              ) : (
                <img
                  src={studyPlaceholderImage}
                  alt={`${patientFullName}-avatar`}
                  className={styles.studyPlaceholderImage}
                />
              )}

              <PatientNameTooltip name={patientFullName}>
                <div
                  className={cn(
                    styles.fullName,
                    !displayAssetID && styles.paddingForFullName,
                  )}
                >
                  {patientFullName}
                </div>
              </PatientNameTooltip>
            </div>
          );
        },
      },
    ),
    columnHelper.accessor((row) => row.ExternalID, {
      id: PatientsListColumnName.ExternalID,
      enableSorting: true,
      minSize: 50,
      header: formatMessage(ColumnValues[PatientsListColumnName.ExternalID]),
      cell: (cell) => {
        const patientExternalID = cell.getValue();

        return (
          <div className={styles.infoInCell}>
            <span className={styles.forWhiteSpace}>{patientExternalID}</span>
          </div>
        );
      },
    }),
    columnHelper.accessor((row) => row.PersonalData?.DateOfBirth, {
      id: PatientsListColumnName.DateOfBirth,
      enableSorting: true,
      minSize: 50,
      header: formatMessage(ColumnValues[PatientsListColumnName.DateOfBirth]),
      cell: (cell) => {
        const dateOfBirth = cell.getValue();
        const patientBirthDate =
          dateOfBirth &&
          formatDate(new Date(dateOfBirth), {
            dateStyle: 'long',
          });

        return (
          <div className={styles.infoInCell}>
            <span className={styles.forWhiteSpace}>{patientBirthDate}</span>
          </div>
        );
      },
    }),
    columnHelper.accessor((row) => row.ID, {
      id: PatientsListColumnName.Doctors,
      enableSorting: false,
      minSize: 50,
      header: formatMessage(ColumnValues[PatientsListColumnName.Doctors]),
      cell: (cell) => {
        const patientID = cell.getValue();

        return <PatientDoctrosCell patientID={patientID} />;
      },
    }),
    columnHelper.accessor((row) => row.ID, {
      id: PatientsListColumnName.Studies,
      meta: { isVisible: !isFDA } as ColumnMeta,
      enableSorting: false,
      minSize: 50,
      header: formatMessage(ColumnValues[PatientsListColumnName.Studies]),
      cell: (cell) => {
        const patientID = cell.getValue();

        return (
          <div>
            <StudiesCounters patientID={patientID} />
          </div>
        );
      },
    }),
  ].filter(
    (column): column is typeof column & { meta: ColumnMeta } =>
      (column.meta as ColumnMeta | undefined)?.isVisible !== false,
  );

  return columnsScheme;
};
