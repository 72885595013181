import { useMemo } from 'react';

import { useAppSelector } from '@/shared/hooks/store';
// TODO: 2 entities - this file should be in the feature slice
import { Condition } from '@/shared/api/protocol_gen/model/dto_report_condition';
import { Tooth } from '@/shared/api/protocol_gen/model/dto_report_tooth';
import { ToothStatus } from '@/shared/config';
import { FeatureFlag } from '@/shared/api/protocol_gen/model/dto_organization';

import { organizationModel } from '@/entities/organization';

import {
  conditionModel,
  getToothStatus,
  shouldConditionItemBeShown,
} from '../../condition';
import { reportsModel } from '../../reports';
import { ToothType } from '../config/tooth.type';

const getToothConditions = (toothID: string, conditions: Condition[]) =>
  conditions.filter((condition) => condition?.Tooth?.ToothID === toothID);

export type ToothWithStatus = Tooth & {
  toothStatus: ToothStatus;
};

type UseToothPropsReturnType = {
  teethWithStatus: Record<string, ToothWithStatus>;
  counters: Record<ToothType, number[]>;
  showLowProbability: boolean;
};

type UseToothProps = (props: {
  reportID: string;
  teeth: Tooth[];
}) => UseToothPropsReturnType;

// TODO: to be refactored and covered by tests
export const useToothProps: UseToothProps = ({ reportID, teeth }) => {
  const report = useAppSelector(reportsModel.selectors.selectByID(reportID));
  const showLowProbability = report?.Settings?.LowProbabilityMode ?? false;

  const isFDA = useAppSelector(
    organizationModel.selectors.selectIsFeatureActiveByName(
      FeatureFlag.FeatureFlag_FDA_SubmissionView,
    ),
  );

  const isFDANonAided = useAppSelector(
    organizationModel.selectors.selectIsFeatureActiveByName(
      FeatureFlag.FeatureFlag_FDA_NonAidedVersion,
    ),
  );

  const allConditions = useAppSelector((state) =>
    conditionModel.selectors.selectByReportID(state, reportID),
  );

  const filteredConditions = useMemo(
    () =>
      allConditions.filter((condition) =>
        shouldConditionItemBeShown(showLowProbability)(condition),
      ),
    [allConditions, showLowProbability],
  );

  const counters: Record<ToothType, number[]> = {
    lowProbability: [],
    unhealthy: [],
    treated: [],
    healthy: [],
    missing: [],
    all: [],
  };

  const teethConditions = teeth.reduce(
    (acc, tooth) => ({
      ...acc,
      [tooth.ID]: getToothConditions(tooth.ID, filteredConditions),
    }),
    {} as Record<string, Condition[]>,
  );

  const teethWithStatus = teeth.reduce(
    (acc, tooth) => {
      const toothConditions = teethConditions[tooth.ID];
      const toothISO = tooth.Numeration?.ISO;

      if (toothISO) {
        counters.all.push(toothISO);
      }

      const toothWithStatus: ToothWithStatus = {
        ...tooth,
        toothStatus: getToothStatus(
          toothConditions,
          showLowProbability,
          isFDA,
          isFDANonAided,
        ),
      };

      const toothWithStatusISO = toothWithStatus.Numeration?.ISO;

      if (
        toothWithStatus.toothStatus === 'lowProbability' &&
        toothWithStatusISO
      ) {
        counters.lowProbability.push(toothWithStatusISO);
      } else if (
        toothWithStatus.toothStatus === 'unhealthy' &&
        toothWithStatusISO
      ) {
        counters.unhealthy.push(toothWithStatusISO);
      } else if (
        toothWithStatus.toothStatus === 'treated' &&
        toothWithStatusISO
      ) {
        counters.treated.push(toothWithStatusISO);
      } else if (
        toothWithStatus.toothStatus === 'healthy' &&
        toothWithStatusISO
      ) {
        counters.healthy.push(toothWithStatusISO);
      }

      return { ...acc, [tooth.ID]: toothWithStatus };
    },
    {} as Record<string, ToothWithStatus>,
  );

  return { teethWithStatus, counters, showLowProbability };
};
