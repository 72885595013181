import React, { FC, useState, ReactNode } from 'react';
import cn from 'classnames';

import {
  UltimateMedicalImage,
  UltimateMedicalImageMode,
} from '@/shared/graphics/RenderComponents/Presets/UltimateMedicalImage';
import { Annotation } from '@/shared/graphics/RenderComponents/AnnotationsLayer/Annotation';
import { AnnotationOrEraser } from '@/shared/graphics/RenderComponents/Presets/DicomImageWithAnnotations';
import {
  DEFAULT_MEDICAL_IMAGE_HEIGHT,
  MedicalImageInterface,
} from '@/shared/config';
import { useAppDispatch } from '@/shared/hooks';
import { PolygonMask } from '@/shared/graphics/RenderComponents/RCPolygonMask/RCPolygonMask';

import { toothModel } from '@/entities/tooth';
import { assetsModel } from '@/entities/assets';
import { useCheckReportSignature } from '@/entities/reports';

import { IContext } from 'graphics';

import { Skeleton } from '../Skeleton/Skeleton';
import { Icon } from '../Icon/Icon';
import { Checkbox } from '../Checkbox/Checkbox';

import styles from './MedicalImage.module.scss';

const INITIAL_WIDTH = 100;

export type MedicalImageProps = {
  onClick?: () => void;
  medicalImageLabel?: ReactNode;
  ww?: number;
  wc?: number;
  sharpness?: number;
  inverted?: boolean;
  mode?: UltimateMedicalImageMode;
  annotations?: Annotation[];
  currentAnnotation?: AnnotationOrEraser;
  onAnnotationsChanged?: React.Dispatch<React.SetStateAction<Annotation[]>>;
  onSharpnessChanged?: React.Dispatch<React.SetStateAction<number>>;
  onWWWCChanged?: React.Dispatch<
    React.SetStateAction<{ ww: number; wc: number }>
  >;
  onRemoveMedicalImage?: (medicalImage: MedicalImageInterface) => void;
  focused?: boolean;
  onDragMedicalImage?: (medicalImage: MedicalImageInterface) => void;
  masks?: PolygonMask[];
  className?: string;
  checkIsTheMedicalImageSelected?: (src: string) => boolean;
  medicalImage: MedicalImageInterface;
  toggled?: boolean;
  order?: number;
  canUserDeleteSlices?: boolean;
  toggleSelected?: (medicalImage: MedicalImageInterface) => void;
};

export const MedicalImage: FC<MedicalImageProps> = (props) => {
  const {
    onClick,
    medicalImageLabel,
    mode,
    medicalImage,
    annotations,
    currentAnnotation,
    onAnnotationsChanged,
    onWWWCChanged,
    onSharpnessChanged,
    onRemoveMedicalImage,
    ww,
    wc,
    sharpness,
    inverted,
    focused,
    masks: mask,
    className,
    onDragMedicalImage,
    toggleSelected,
    checkIsTheMedicalImageSelected,
    toggled,
    order,
    canUserDeleteSlices,
  } = props;

  const [size, setSize] = useState({
    w: INITIAL_WIDTH,
    h: DEFAULT_MEDICAL_IMAGE_HEIGHT,
  });

  const [isImageReady, setIsImageReady] = useState(false);
  const [dragging, setDragging] = useState(false);

  const dispatch = useAppDispatch();

  const { checkReportSignature } = useCheckReportSignature();

  const isImgSelected =
    checkIsTheMedicalImageSelected &&
    checkIsTheMedicalImageSelected(medicalImage.src);

  const onDicomLoaded = (context: IContext) => {
    const aspectRatio = context.width / context.height;
    const newWidth = aspectRatio * DEFAULT_MEDICAL_IMAGE_HEIGHT;
    setSize({
      w: newWidth,
      h: DEFAULT_MEDICAL_IMAGE_HEIGHT,
    });

    setIsImageReady(true);
  };

  const handleDeleteMedicalImage = async (
    e: React.MouseEvent<SVGSVGElement>,
  ) => {
    e.stopPropagation();

    const deleteImage = async () => {
      if (onRemoveMedicalImage) {
        onRemoveMedicalImage(medicalImage);
      }

      if (canUserDeleteSlices) {
        const response = await dispatch(
          assetsModel.thunks.deleteAsset({
            AssetID: medicalImage.assetID,
          }),
        ).unwrap();

        if (response?.Tooth) {
          dispatch(toothModel.actions.setNewestOne(response?.Tooth));
        }
      }
    };

    if (isImgSelected) {
      checkReportSignature({
        onSignatureChecked: deleteImage,
      });
    } else {
      await deleteImage();
    }
  };

  const handleDragStart = () => {
    if (onDragMedicalImage) {
      onDragMedicalImage(medicalImage);
    }

    setDragging(true);
  };
  const handleDragEnd = () => {
    if (onDragMedicalImage) {
      onDragMedicalImage({} as MedicalImageInterface);
    }
    setDragging(false);
  };

  return (
    <div
      data-group={`${medicalImage.groupName}`}
      className={cn(styles.container, className, focused && styles.focused)}
      style={{ width: toggled ? size.w / 2 : size.w }}
      draggable={!!onDragMedicalImage}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
    >
      <div
        className={styles.imageContainer}
        onClick={() => isImageReady && onClick && onClick()}
      >
        {!isImageReady && (
          <Skeleton
            width="100%"
            borderRadius="12px"
            height="100%"
            className={styles.imageSkeleton}
          />
        )}

        <UltimateMedicalImage
          src={{ url: medicalImage.src, kind: medicalImage?.kind ?? 'dicom' }}
          style={{
            width: toggled ? size.w / 2 : size.w,
            height: toggled ? size.h / 2 : size.h,
          }}
          mode={mode as UltimateMedicalImageMode}
          ww={ww ?? 0}
          wc={wc ?? 0}
          sharpness={sharpness ?? 0}
          annotations={annotations ?? []}
          inverted={inverted ?? false}
          currentAnnotation={currentAnnotation as AnnotationOrEraser}
          onAnnotationsChanged={onAnnotationsChanged}
          onSharpnessChanged={onSharpnessChanged}
          onWWWCChanged={onWWWCChanged}
          onLoaded={onDicomLoaded}
          masks={mask}
          className={styles.medicalImage}
        />

        {(onRemoveMedicalImage || canUserDeleteSlices) && !dragging && (
          <div className={styles.removeButtonContainer}>
            <Icon
              name="delete"
              size={toggled ? 16 : 24}
              onClick={handleDeleteMedicalImage}
            />
          </div>
        )}
      </div>

      {medicalImageLabel && (
        <p className={cn(styles.medicalImageLabel, 'p2')}>
          {medicalImageLabel}
        </p>
      )}

      {onDragMedicalImage && (
        <div className={styles.selectSliceControl}>
          <Checkbox
            boxClassName={styles.selectCheckbox}
            checked={isImgSelected || false}
            onChange={() => {
              if (toggleSelected) {
                toggleSelected(medicalImage);
              }
            }}
          />
        </div>
      )}

      {typeof order === 'number' && order >= 0 ? (
        <div
          className={cn(styles.numeration, isImgSelected && styles.selected)}
        >
          {isImgSelected && <Icon name="check" size={16} />}
          <span className={styles.order}>{order + 1}</span>
        </div>
      ) : null}

      <div className={styles.orientWrapper}>
        <div className={styles.orientContainer}>
          <span className={styles.medicalImageText}>
            {medicalImage?.leftSideOrient}
          </span>
          <span className={styles.medicalImageText}>
            {medicalImage?.rightSideOrient}
          </span>
        </div>
      </div>
    </div>
  );
};
