import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import { Organization } from '@/shared/api/protocol_gen/model/dto_organization';
import { LoadingStateType, SliceName } from '@/shared/config';

import { setCurrentOrganizationByID } from './organizationSlice.thunks';

export const organizationAdapter = createEntityAdapter<Organization>({
  selectId: (organization) => organization.ID,
});

const initialStateFlags = {};

const initialState = () => ({
  currentOrganizationID: '',
  stateFlags: initialStateFlags,
  loading: 'idle',
});

const organizationSlice = createSlice({
  name: SliceName.organization,
  initialState: organizationAdapter.getInitialState(initialState()),
  reducers: {
    addOne: organizationAdapter.addOne,
    setOne: organizationAdapter.setOne,
    setNewestOne: (state, action: PayloadAction<Organization>) => {
      if (action.payload.Deleted?.Deleted) {
        organizationAdapter.removeOne(state, action.payload.ID);
      }

      const currentRevisionNumber =
        state.entities[action.payload.ID]?.Revision?.Number ?? 0;
      const payloadRevisionNumber = action.payload.Revision?.Number ?? 0;

      if (payloadRevisionNumber > currentRevisionNumber) {
        organizationAdapter.setOne(state, action.payload);
      }
    },
    setNewestMany: (state, action: PayloadAction<Organization[]>) => {
      const items = action.payload.filter((item) => {
        const currentRevisionNumber =
          state.entities[item.ID]?.Revision?.Number ?? 0;
        const payloadRevisionNumber = item.Revision?.Number ?? 0;

        return payloadRevisionNumber > currentRevisionNumber;
      });

      organizationAdapter.setMany(state, items);
    },
    removeOne: organizationAdapter.removeOne,
    removeAll: organizationAdapter.removeAll,
    setLoading: (state, action: PayloadAction<LoadingStateType>) => {
      state.loading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setCurrentOrganizationByID.fulfilled, (state, action) => {
      state.currentOrganizationID = action.payload;
    });
  },
});

export const { actions } = organizationSlice;

export default organizationSlice.reducer;
